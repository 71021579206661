<template lang="pug">
div.language-selector(
  @mouseover="handleMousemove(true)"
  @mouseleave="handleMousemove(false)"
  @click="handleClick()"
)
  div.flag
    .eclipse-outside
      .eclipse-inside
        img(:src="baseUrl + '/storage/flag/' + locale + '.png'")

    .title
      | {{ locale.toUpperCase() }}
      .icon
        img(src="@/assets/img/svg/chevron-bottom.svg")

  transition(name="expand")
    .list-containner(v-if="showDropdown")
      ul.options
        li.option(
          v-for="locale in availableLocales"
          @click="toRouteName(getCurrentRouteName(this.$route.name), locale, true)"
        )
          div.flag
            .eclipse-outside
              .eclipse-inside
                img(:src="baseUrl + '/storage/flag/' + locale.toLowerCase() + '.png'")

            .title
              | {{ locale.toUpperCase() }}

</template>

<script>
import { defineComponent, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { storeToRefs } from 'pinia'
import { toRouteName, getCurrentRouteName } from '@/helpers/route'
import menuControl from '@/composables/menuControl.js'
import { useLanguageStore } from '@/stores'

export default defineComponent({
  name: 'LanguageSelector',

  setup() {
    const { getLanguageCodes, getLanguageFlag } = storeToRefs(useLanguageStore())
    const baseUrl = window.location.origin

    const { locale } = useI18n()

    const showDropdown = ref(false)

    const { handleMousemove, handleClick } = menuControl(showDropdown, 'languages')

    return {
      availableLocales: getLanguageCodes,
      baseUrl,
      locale: locale.value,
      showDropdown,
      toRouteName,
      getCurrentRouteName,
      handleMousemove,
      handleClick,
      getLanguageFlag
    }
  }
})
</script>
